<template>
    <section id="workingArea">
        <div class="swapBtn_sm d-flex align-items-center">
            <router-link to="/starcards">
            Mint
            </router-link>
            <router-link to="/holders" class="btn2">
            ☆Fee
            </router-link>
        </div>
        <div id="tabSec" class="align-items-center" style="max-width:600px;">
            <div class="balance q-px-md" style="max-width:600px;">
                <div
                    class="col-12 flex justify-between items-center q-pa-sm text-white">
                    <div class="q-my-md text-center">
                        <span class="label">Fee Share</span><br>
                        <span class="label">{{getClaimableReward}}</span>
                        <span class="token"> ASTR</span>
                    </div>
                    <div class="text-center">
                        <span class="label">Claimed</span><br>
                        <span class="label">{{getClaimedReward}}</span>
                        <span class="token"> ASTR</span>
                    </div>
                </div>
                <div class="text-h7 col-12 justify-center flex q-mb-md" style="padding-bottom:10px;">
                    <div class="btn" v-if="!isMetaMaskConnected" @click="connectWallet">Connect Wallet</div>
                    <div class="btn" v-if="isMetaMaskConnected" @click="claim">Claim Your Share</div>
                </div>
                <div class="sol q-mb-md">
                    <div class="text-h7 col-12 justify-center flex q-mb-md" style="padding-top:30px; border-top:solid .1px; border-color: #9db2ef;">
                <p class="label justify-center" style="text-align:center; font-size:14px; ">View Your StarCards Below</p>
                    </div>
                    <div class="align-items-center walletAddress">
                        <q-input class="address"
                            standout="bg-indigo-5 text-black" type="text" placeholder="Wallet Address"
                            v-model="address"
                            :bottom-slots="false" :hide-bottom-space="true">
                        </q-input>
                    </div>
                </div>
                <div class="walletBtn" @click="search">Submit</div>
            </div>
        </div>
        <div class = "row my-7 mx-0 text-white q-mt-lg" 
            v-if="this.$store.state.starCardsnft.searchResult" 
            style="width:100%; padding-left:5px;">
            <div class="col-lg-3 text-white col-md-4 col-sm-12 flex justify-center q-mt-lg" 
                v-for="(imageURI,idx) in this.$store.state.starCardsnft.imageURIs"
                :key="idx">
                <div class="nft_card">
                    <div class="nft_card_image" v-lazy-container="{ selector: 'img' }">
                        <img
                            :data-src="imageURI"
                            :data-loading="loadimage"
                            contain
                        />
                    </div>
                    <div class="sub_panel text-justify text-center q-mt-sm">
                        <div class = "tokenNames" style="font-weight: 600; color: rgb(194, 86, 207);">{{showShortName(idx)}}</div>
                        <div class = "tokenRarity" style="font-weight: 600;">Rarity Rank: {{tokenAttributes(idx).value}}</div>
                        <div class="walletBtn_sm" @click="showModal(idx)">Send</div>
                    </div>
                </div>              
           </div>
        </div>
        <div v-if="showModalSend" class="overlay">
            <div class="modal__content_view" v-click-outside="onClickOutside">
                <div class="model__content__item">
                    <div>Send Token</div>
                        <q-btn icon="close" flat round dense
                            @click="showModalSend = !showModalSend" color="white">
                    </q-btn>
                </div>
                <div class="model__content__item address q-my-sm">
                    <q-input standout="bg-indigo-5 text-white"  
                        type="text" v-model="recipientAddress" placeholder="Address"
                        :bottom-slots="false" :hide-bottom-space="true" class="search">
                        </q-input>
                </div>
                <div class="model__content__item">
                    <div id="claim"
                        @click="send" class="walletBtn q-mt-sm">Send</div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import { BigNumber } from 'bignumber.js'
    import vClickOutside from 'v-click-outside'

    export default {
        components: {
        },
        data() {
            return {
                loadimage: require("@/assets/loading.gif"),
                pending: false,
                error: false,
                address: null,
                showModalSend: false,
                recipientAddress: null,
                tokenId: null,
                transfered: false
            }
        },
        mounted() {
        },
        watch:{
            showModalSend:function(newValue){
                if(!newValue){
                    this.research();
                    this.transfered = false;
                }
            }
        },
        computed: {   
            isMetaMaskConnected() {
                return this.$store.state.account != null;
            },   
            getClaimableReward() {
                if(this.$store.state.starCardsnft.claimableRewards > 0)
                    return this.getDecimalData(BigNumber(this.$store.state.starCardsnft.claimableRewards)
                    .shiftedBy(-18));

                return '0.00';
            },
            getClaimedReward() {
                if(this.$store.state.starCardsnft.claimedRewards > 0)
                    return this.getDecimalData(BigNumber(this.$store.state.starCardsnft.claimedRewards)
                    .shiftedBy(-18));

                return '0.00';
            }, 
        },
        methods: {
            connectWallet() {
                this.$store.dispatch('connect');
            },
            onClickOutside (event) {
                this.showModalSend = false;
            },
            showModal(index) {
                this.showModalSend = true;
                this.tokenId = this.$store.state.starCardsnft.tokenIds[index];
            },
            async search() {
                this.pending = true;
                try {
                    let result;
                    result = await this.$store.dispatch('getTokenIdsOfOwner', {
                        account:this.address
                    })

                    if (!result) {
                        this.pending = false;
                        this.error = true;
                        return;
                    }

                    await this.$store.dispatch('constructTokenInfo')

                    this.error = false;
                }
                catch (e) {
                    this.error = e;
                }
                this.pending = false;
            },
            showShortName(index) {
                let name = this.$store.state.starCardsnft.tokenNames[index];
                if (name.length < 20)
                    return name
                else
                    return (
                        '"' + name.substring(0, 10) +
                        '...' +
                        name.substring(name.length - 8, name.length) + '"'
                    );
            },
            tokenPrice(index) {
                return BigNumber(this.$store.state.starCardsnft.tokenPrices[index]).shiftedBy(-18);
            },
            tokenAttributes(index) {
                return this.$store.state.starCardsnft.tokenAttributes[index][0];
            },
            async research() {
                if (this.transfered)
                {
                    try {
                        await this.$store.dispatch('constructTokenInfo')
                    }
                    catch (e) {
                        this.error = e;
                    }
                }
            },
            send() {
                this.$store.dispatch('transferToken', 
                    {
                        from:this.address, 
                        to:this.recipientAddress, 
                        tokenID:this.tokenId
                    }
                )
                this.transfered = true;
            },
            claim() {
                this.$store.dispatch('claimForStarCardsDistributor')
            },
            getDecimalData(value) {
                let limit_xxx = BigNumber(1000);
                let limit_xx = BigNumber(1);
                let limit_x = BigNumber(1).shiftedBy(-3);
                let limit_sm = BigNumber(1).shiftedBy(-6);
                let limit_md = BigNumber(1).shiftedBy(-9);
                let limit_lg = BigNumber(1).shiftedBy(-12);

                if (limit_lg.isGreaterThan(value)) {
                    return value.toString();
                }
                else if (limit_md.isGreaterThan(value)) {
                    return value.decimalPlaces(12, 1).toString();
                }
                else if (limit_sm.isGreaterThan(value)) {
                    return value.decimalPlaces(9, 1).toString();
                }
                else if (limit_x.isGreaterThan(value)) {
                    return value.decimalPlaces(6, 1).toString();
                }
                else if (limit_xx.isGreaterThan(value)) {
                    return value.decimalPlaces(4, 1).toString();
                }
                else if (limit_xxx.isGreaterThan(value)) {
                    return value.decimalPlaces(2, 1).toString();
                }
                else {
                    return value.decimalPlaces(0, 1).toString();
                }
            }
        }
    }
</script>
<style>
</style>
